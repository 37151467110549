@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,100&family=Poppins:wght@100;300;500&family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: Ahsing;
  src: url('./assets/fonts/Ahsing-Regular.otf');
}

@font-face {
  font-family: Neuron;
  src: url('./assets/fonts/Neuron-Heavy.otf');
}

@font-face {
  font-family: IdealSans;
  src: url('./assets/fonts/IdealSans-Medium-Pro.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: IdealSans;
  src: url('./assets/fonts/IdealSans-Bold-Pro.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: IdealSans;
  src: url('./assets/fonts/IdealSans-Light-Pro.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Aicho;
  src: url('./assets/fonts/Aicho.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: CyberAngel;
  src: url('./assets/fonts/Cyber-Angel.otf');
  font-weight: 300;
  font-style: normal;
}

body {
  font-family: 'CyberAngel', sans-serif;
  /* font-weight: 300; */
  margin: 0;

  color: #fff;
  /* background: #232323;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */

  /* background: linear-gradient(-45deg, #7046DC, #F45B78, #F2C6CF, #09A9E3);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite; */
	height: 100vh;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

@viewport {
  zoom: 1.0;
  user-zoom: fixed;
  max-zoom: 1.0;
  min-zoom: 1.0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gm-style-cc { display:none; }

.no-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.no-scroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}